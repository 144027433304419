<template>
  <b-container>
    <div class="row">
      <div class="col-md-6">
        <h3 class="text-center text-secondary section-title mb-3">Atuação</h3>
        <p class="">
          Os projetos de infraestrutura e saneamento básico são um conjunto de
          soluções de engenharia embasadas em amplos estudos, que contemplam a
          análise da topografia, hidrografia e urbanização da área para
          conceituação da alternativa técnica adequada a cada empreendimento.
        </p>
        <p class="">
          Os dimensionamentos e desenvolvimentos dos projetos atendem as normas
          e convenções da boa engenharia que garantem um projeto preciso e
          operacional, proporcionando a execução de uma obra como o projetado.
        </p>
        <p class="">
          Desse modo a <strong>Areté Infraestrutura</strong> contribui
          desenvolvendo soluções em estudos preliminares, projetos conceituais,
          projetos básicos ou legais e projetos executivos.
        </p>
      </div>
      <div class="col-md-6">
        <div class="accordion" role="tablist">
          <b-list-group>
            <b-list-group-item
              button
              v-b-toggle.accordion-1
              variant="arete-light-purple"
            >
              <span class="font-italic text-secondary">
                <font-awesome-icon icon="fa-angle-right" class="mr-2" />
                Sistema de esgotamento sanitário
              </span>
            </b-list-group-item>
          </b-list-group>
          <b-collapse
            id="accordion-1"
            accordion="atuacao-accordion"
            appear
            visible
            class="list-group-item"
          >
            <ul>
              <li>Rede coletora de esgoto</li>
              <li>Coletor tronco de esgoto</li>
              <li>Emissário de esgoto</li>
              <li>Estação elevatória de esgoto (EEE)</li>
              <li>Recalque de esgoto</li>
              <li>Conduto forçado de esgoto / Sifão invertido</li>
            </ul>
          </b-collapse>

          <b-list-group>
            <b-list-group-item
              button
              v-b-toggle.accordion-2
              variant="arete-light-purple"
            >
              <span class="font-italic text-secondary">
                <font-awesome-icon icon="fa-angle-right" class="mr-2" />
                Sistema de abastecimento de água
              </span>
            </b-list-group-item>
          </b-list-group>
          <b-collapse
            id="accordion-2"
            accordion="atuacao-accordion"
            class="list-group-item"
          >
            <ul>
              <li>Rede de distribuição de água</li>
              <li>Adutora de água por gravidade</li>
              <li>Estação elevatória de água tratada (EEAT)</li>
              <li>Booster</li>
              <li>Adutora de água por recalque</li>
              <li>Reservatório de água</li>
            </ul>
          </b-collapse>

          <b-list-group>
            <b-list-group-item
              button
              v-b-toggle.accordion-3
              variant="arete-light-purple"
            >
              <span class="font-italic text-secondary">
                <font-awesome-icon icon="fa-angle-right" class="mr-2" />
                Outros
              </span>
            </b-list-group-item>
          </b-list-group>
          <b-collapse
            id="accordion-3"
            accordion="atuacao-accordion"
            class="list-group-item"
          >
            <ul>
              <li>Elaboração de pedido e renovação de diretrizes</li>
              <li>
                Descrição de faixas de servidão e vielas sanitárias para
                aprovação na Sanasa e Prefeitura Municipal de Campinas
              </li>
              <li>
                Compatibilização de redes de água, redes de esgoto com galerias
                de águas pluviais, com diagnóstico das interferências, propondo
                soluções que propiciem maior eficiência no momento da execução
                destas obras
              </li>
              <li>
                Serviço de vídeo inspeção robotizado e parecer técnico de
                anomalias em redes de água, redes de esgoto e galerias de águas
                pluviais
              </li>
            </ul>
          </b-collapse>

          <b-list-group>
            <b-list-group-item
              button
              v-b-toggle.accordion-4
              variant="arete-light-purple"
            >
              <span class="font-italic text-secondary">
                <font-awesome-icon icon="fa-angle-right" class="mr-2" />
                Consultoria
              </span>
            </b-list-group-item>
          </b-list-group>
          <b-collapse
            id="accordion-4"
            accordion="atuacao-accordion"
            class="list-group-item"
          >
            <ul>
              <li>
                Análise das situações apresentadas, disponibilizando soluções
                diferenciadas e viáveis para os empreendimentos em estudos e
                projetos técnicos de infraestrutura e saneamento básico.
              </li>
            </ul>
          </b-collapse>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "BusinessAreaSection",
};
</script>
