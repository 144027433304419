import Vue from "vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import the icons you want to use */
import {
  faCaretRight,
  faAngleRight,
  faQuestionCircle,
  faQuoteLeft,
  faQuoteLeftAlt,
  faQuoteRight,
  faQuoteRightAlt,
  faLightbulb,
  faDownload,
  faExclamationTriangle,
  faTimesCircle,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import {
  faLinkedinIn,
  faLinkedin,
  faInstagram,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";

/* add the imported icons to the library */
library.add(
  faCaretRight,
  faAngleRight,
  faQuestionCircle,
  faLightbulb,
  faQuoteLeft,
  faQuoteLeftAlt,
  faQuoteRight,
  faQuoteRightAlt,
  faLinkedin,
  faLinkedinIn,
  faInstagram,
  faInstagramSquare,
  faDownload,
  faExclamationTriangle,
  faTimesCircle,
  faUser
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
