<template>
  <footer>
    <b-container>
      <b-row align-h="around">
        <b-col cols-md="auto">
          <h4>Contato</h4>
          <!-- <p class="mb-0">Ricardo Correia</p>
          <p>Especialista em infraestrutura urbana e saneamento</p>
          <p>(19) 99290 8592</p> -->
          <p>
            <a href="mailto:contato@areteinfra.com"> contato@areteinfra.com </a>
          </p>
        </b-col>
        <b-col cols-md="auto">
          <h4>Endereço</h4>
          <!-- <p class="mb-0">Rua José de Alencar, 1000, Centro, 13015-000</p> -->
          <p>Campinas - SP</p>
        </b-col>
        <b-col cols-md="auto">
          <div class="social-list">
            <a
              href="https://www.linkedin.com/company/areteinfra/about/"
              target="_blank"
              title="Linkedin"
              alt="Linkedin"
            >
              <font-awesome-icon icon="fab fa-linkedin" size="2x" />
            </a>
            <!-- https://www.instagram.com/arete_infra/ -->
            <a
              href="https://www.instagram.com/arete_infra/"
              target="_blank"
              title="Instagram"
              alt="Instagram"
            >
              <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
            </a>
          </div>
        </b-col>
        <b-col class="text-right">
          <InternalAccessButton />
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import InternalAccessButton from "./InternalAccessButton.vue";

export default {
  name: "TheFooter",
  components: { InternalAccessButton },
};
</script>

<style lang="scss" scoped>
footer {
  background-color: var(--arete-purple);
  color: var(--arete-light-purple);
  padding: 2rem;
  min-height: 15rem;
}

.social-list {
  width: 100%;
  display: flex;
  gap: 1rem;
}
</style>
