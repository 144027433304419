<template>
  <div class="page-container">
    <TheHeader />

    <section class="bg-arete-dark-grey px-5 py-4">
      <AboutCompanySection />
    </section>

    <section class="bg-primary text-white text-center">
      <b-container>
        <h5 class="font-italic m-0">
          <font-awesome-icon icon="fa-quote-left-alt" class="mr-2" />
          A palavra <strong>Areté</strong> tem origem grega e indica
          <strong>excelência</strong> e <strong>virtude</strong>
          <font-awesome-icon icon="fa-quote-right-alt" class="ml-3" />
        </h5>
      </b-container>
    </section>

    <section>
      <BusinessAreaSection />
    </section>

    <section>
      <ClientsSection />
    </section>

    <TheFooter />
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter.vue";

// sections
import ClientsSection from "@/components/ClientsSection.vue";
import BusinessAreaSection from "@/components/BusinessAreaSection.vue";
import AboutCompanySection from "@/components/AboutCompanySection.vue";

// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "HomeView",
  components: {
    TheHeader,
    TheFooter,
    ClientsSection,
    BusinessAreaSection,
    AboutCompanySection,
  },
};
</script>

<style>
section {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
</style>
