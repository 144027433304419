<template>
  <b-container>
    <div
      class="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center align-items-stretch"
    >
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title text-secondary">Nosso negócio</h4>
            <p class="card-text">
              A Areté Infraestrutura é uma empresa especializada na elaboração
              de Projetos de Infraestrutura e Saneamento Básico com atuação em
              todo território nacional.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title text-secondary">Visão</h4>
            <p class="card-text">
              Ser a empresa líder na geração de valor para os empreendimentos e
              na satisfação para os clientes.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title text-secondary">Propósito</h4>
            <p class="card-text">
              Projetar obras pautadas pela aplicação de conceitos e técnicas
              adequados, buscando garantir um projeto eficiente e com elevada
              otimização dos custos de implantação.
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card h-100">
          <div class="card-body">
            <h4 class="card-title text-secondary">Valores</h4>
            <p class="card-text">
              Nosso valor primordial é a excelência. Ela representa as virtudes
              que buscamos disseminar entre nossos colaboradores para que se
              reflitam em nossos clientes.
            </p>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "AboutCompanySection",
};
</script>

<style lang="scss" scoped>
.col:has(.card) {
  margin-bottom: 1rem;
}

.card-title {
  font-family: "Merriweather", serif;
}
</style>
