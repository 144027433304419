import firebase from "firebase/app";
// import "firebase/auth";
import "firebase/firestore";
// import "firebase/functions";
import "firebase/storage";
import firebaseConfig from "./firebaseConfig";

const app = firebase.initializeApp(firebaseConfig);

// firebase utils
const db = firebase.firestore();
const storage = app.storage();

// if (process.env.NODE_ENV !== "production") {
//   // connect to emulators
//   storage.useEmulator("localhost", 9199);
// }

const getSettings = async () => {
  const settingsDoc = await db.collection("settings").doc("default").get();
  return settingsDoc.data();
};

const getAdImageURL = async () => {
  // select a random ad image from ads collection
  const adsCollection = await db
    .collection("ads")
    .where("deleted", "==", false)
    .get();
  const urls = adsCollection.docs.map((doc) => doc.data().link);
  const randomAd = urls[Math.floor(Math.random() * urls.length)];
  return randomAd;
};

const getClientsImages = async () => {
  const clientsCollection = db.collection("clients");
  const activeClientsQuery = clientsCollection.where("deleted", "==", false);

  const clients = await activeClientsQuery.get();

  return clients.docs.map((doc) => ({
    ...doc.data(),
    id: doc.id,
  }));
};

/**
 * Generate a download link for a file
 * @param {String} fileId - The file id to generate the download link (uuid)
 * @returns The download link
 */
const generateDownloadLink = async (fileId) => {
  const fileRef = storage.ref(`uploads/${fileId}`);
  const downloadLink = await fileRef.getDownloadURL();
  return downloadLink;
};

const getFileDetails = async (fileId) => {
  const fileRef = storage.ref(`uploads/${fileId}`);
  const fileDetails = await fileRef.getMetadata();
  return fileDetails;
};

export {
  getSettings,
  getFileDetails,
  generateDownloadLink,
  getAdImageURL,
  getClientsImages,
};
