<template>
  <header class="bg-white shadow-sm py-3 site-header">
    <b-container>
      <b-row align-v="center" align-h="center" no-gutters>
        <b-col class="text-center">
          <!-- logo -->
          <b-link href="/">
            <img
              src="@/assets/arete-logo.jpg"
              alt="Arete Infraestrutura"
              class="img-logo"
              title="Arete Infraestrutura"
            />
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
export default {
  name: "TheHeader",
};
</script>

<style lang="scss" scoped>
.site-header {
  height: auto;
  border-bottom: 1px solid #eaeaea;
}
.img-logo {
  height: 12rem;
}
</style>
