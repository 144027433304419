<template>
  <b-container>
    <h3 class="text-secondary text-center section-title">Clientes</h3>
    <b-card-group columns class="mt-3">
      <b-card
        v-for="client in clients"
        :key="client.id"
        body-class="card-body-cliente"
      >
        <b-card-img
          :src="client.link"
          :alt="client.name"
          class="img-fluid logo-cliente"
        ></b-card-img>
      </b-card>
    </b-card-group>
  </b-container>
</template>

<script>
import { getClientsImages } from "@/plugins/firebase";

export default {
  name: "ClientsSection",

  data() {
    return {
      clients: [],
    };
  },

  async created() {
    this.clients = await getClientsImages();
  },
};
</script>

<style lang="scss" scoped>
.card-body-cliente {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 7rem;
}

.logo-cliente {
  max-height: 4rem;

  width: auto;
}
</style>
