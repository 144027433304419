<template>
  <b-button variant="outline-light" :href="internoUrl" target="_blank">
    <!-- area interna -->
    <font-awesome-icon icon="user" class="mr-2" />
    Área Interna
  </b-button>
</template>

<script>
import { getSettings } from "@/plugins/firebase";

export default {
  name: "InternalAccessButton",

  data() {
    return {
      internoUrl: "http://localhost:8080",
    };
  },

  async created() {
    const settings = await getSettings();
    this.internoUrl = settings.internoUrl || "http://localhost:8080";
  },
};
</script>

<style lang="scss" scoped></style>
